<template>
  <el-container
    v-loading="loading"
  >
    <el-header style="display: flex; align-items: center">
      <div style="display: flex; justify-content: flex-start; cursor: default">
        <span>全部考试 > </span><span>{{ examName }} > </span
        ><span @click="$router.back()">{{ subjectName }} > </span
        ><span style="color: #563279"> {{ mornitorName }} </span>
      </div>
    </el-header>
    <el-main>
      <div style="display: flex; align-items: center; width: 100%">
        <div
          style="color: #333333; font-size: 20px; font-weight: bold; flex: 1"
        >
          {{ mornitorName }}
        </div>
<!--        <div style="flex: 2">-->
<!--            <el-button class="chooseRoom" @click="getRoom1">-->
<!--                机房1-->
<!--            </el-button>-->
<!--            <el-button class="chooseRoom" @click="getRoom2">-->
<!--              机房2-->
<!--            </el-button>-->
<!--        </div>-->
        <el-input
          placeholder="请输入考生姓名"
          prefix-icon="el-icon-search"
          v-model="studentName"
          style="width: 200px"
          @input="searchStudent"
        >
        </el-input>
        <el-button
          style="
            background: #563279;
            color: #fff;
            margin-left: 20px;
            width: 120px;
          "

        >
          <a style="outline:none;color:#fff;text-decoration:none" href="https://rtc-records-stu.oss-cn-shanghai.aliyuncs.com/record/pmni3wux/079337_6wm75p_917707630996_front/2021-01-08-17-22-07_2021-01-08-17-33-45.m3u8" download="">下载到本地</a>
        </el-button>
      </div>
      <el-table
        :data="videoList"
        tooltip-effect="dark"
        stripe
        style="width: 100%; margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          label="全选"
          type="selection"
          width="55"
          align="center"
        >
        </el-table-column>
        <el-table-column label="文件名称" width="200" align="center">
          <template slot-scope="scope">
            <img
              src="@/assets/image/video.png"
              @click="gotoplay(scope.row.address)"
            />
            <span style="margin-left: 10px">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="监控位置" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.type == 1 ? "前置" : "后置" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          label="录制时长"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="size" label="大小" align="center">
        </el-table-column>
        <el-table-column prop="startTime" label="录制时间" align="center">
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
import { getVideoList } from "r/admin/videorecord";
//import "../../../downloading.js"
export default {
  data() {
    return {
      studentName: "",
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        type: "",
        zkz: "",
        groupId: this.$route.query.groupId,
        name: "",
      },
      sourceArr: [],
      mornitorName: "",
      stripe: true,
      examName: "",
      subjectName: "",
      videoList: [],
      loading:true
    };
  },
  created() {
    this.getvideolist();
    console.log(this.$route.query.groupId,"groupId")
  },
  methods: {
    //获取机房1的监控
    //  getRoom1(){
    //     this.queryInfo.name = '机房1'
    //     window.sessionStorage.setItem('roomname','机房1')
    //     this.loading = true
    //     this.getvideolist()
    //  },
    // //获取机房2监控
    //  getRoom2(){
    //     this.queryInfo.name = '机房2'
    //     window.sessionStorage.setItem('roomname','机房2')
    //     this.loading = true
    //     this.getvideolist()
    //  },
    //获取视频列表
    async getvideolist() {
      this.mornitorName = this.$route.query.mornitorName;
      this.examName = window.localStorage.getItem("examName");
      this.subjectName = window.localStorage.getItem("subjectName");
      //点击机房按钮之后保持本页面
      if(window.sessionStorage.getItem('roomname')){
          this.queryInfo.name = window.sessionStorage.getItem('roomname')
      }
      const { data: res } = await getVideoList(this.queryInfo);
      if (res.code !== 200) {
        this.$message({
          message: res.code,
          type: "error",
        });
      } else {
        let arr = [];
        let list = res.data.list;
        //当获取数据中的time属性为null的时候说明视频被删除了不进行显示，在这里进行判断
        for(let i = 0; i < list.length; i ++){
           if(list[i].time){
             arr.push(list[i])
           }
          //  }else{
          //    this.$message.error("视频已经被删除了")
          //  }
        }
        this.videoList = arr;
        if(this.videoList == arr){
            this.loading = false
        }
        // this.videoList = list;
        // console.log(this.videoList, "视频列表");
      }
    },
    //跳转到播放页面
    gotoplay(address) {
      
      if(address.indexOf('https') == -1){//判断头部是否为https，并更换为https
           var Reg = new RegExp('http')
           var address2 = address.replace(Reg,'https')
          //  console.log('发送地址',address2)
      }
      this.$router.push({
        name: "admin-videorecord-playvideo",
        query: address2,
      });
    },
    //搜索学生
    searchStudent() {
      this.queryInfo.name = this.studentName;
      // console.log(this.queryInfo, "搜索搜索");
      if (
        this.queryInfo.name === this.studentName &&
        this.queryInfo.name !== ""
      ) {
        //赋值成功之后在调用
        this.getvideolist();
      }
    },
    //下载到本地
    // download() {
    //   // const request = require("request");
    //   // const fs = require("fs");
    //   // const path = require("path");
    //   // const child_process = require("child_process");
    //   // const fsextra = require("fs-extra");
    // //解析视频文件
    // const url = "http://rtc-records-stu.oss-cn-shanghai.aliyuncs.com/record/pmni3wux/079337_6wm75p_917707630996_front/2021-01-08-17-22-07_2021-01-08-17-33-45.m3u8"
    
    // const fs = require("fs");
    // console.log(fs,"返回数据fs")

    // var source = fs.readFileSync(url,"utf-8");
    // console.log(source,"访问视频地址")
    // var arr = source.split("\n");
    // arr = arr.filter((item)=>{
    //     return item.match(/\.ts$/);
    // })
    // console.log(arr,"返回数组")

    //   this.$message.success("下载成功");
    // },
    //选中按钮之后触发的函数
    handleSelectionChange(val) {
      this.sourceArr = val;
      // console.log(this.sourceArr);
    },
  },
};
</script>

<style scoped>
.chooseRoom{
  background: #563279;
  color: #fff;
  margin-left: 20px;
}

/* 去除表格的内边框 */
::v-deep .el-table__row > td {
  border: none;
}
/* 去除最下面的边框线 */
::v-deep .el-table::before {
  height: 0px;
}
.evenClass {
  background: red;
}
.oddClass {
  background: orange;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner:hover {
  border: none;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #563279 !important;
  border: 1px solid #563279;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #563279;
  border-radius: 40px;
}
::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 40px;
}
::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: #dcdfe6;
  border-color: #fff;
}
::v-deep .el-checkbox__inner {
  border: none;
  background: #dcdfe6;
}
::v-deep .el-input__inner {
  border: none;
  background: #fafafa;
}
</style>